body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 0 !important;
  height: 0 !important;
}

*:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu .fas {
  width: 20px;
}

.dropdown-menu a {
  color: #222222;
}

.dropdown-menu a:hover {
  text-decoration: underline;
}

.login-box {
  padding-top: 12%;
}

.img-panel {
  height: auto;
  width: 1.8rem;
}

.user-panel {
  border-bottom: 1px solid #4f5962;
}

.content-wrapper {
  min-height: 823px;
}

.custom-control-label.custom-label {
  font-size: 13px;
  color: gray;
  font-weight: 300 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.RichTextEditor__editor___1QqIU {
  height: 150px !important;
}

#subscription-template {
  height: 400px !important;
  overflow: auto;
  border: 1px solid lightgray;
  padding: 5px;
}

#subscription-template .wrapper {
  padding: unset !important;
}

#subscription-template .wrapper h1 {
  font-size: 25px;
  margin-bottom: 20px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

table.table {
  width: 100% !important;
  overflow-x: visible;
}

.card-title {
  margin-bottom: 0 !important;
}

.nav-link .nav-icon {
  width: 30px !important;
}

.pagination {
  margin-top: 30px;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.nav-item .nav-link.active {
  background-color: white !important;
  color: #253270 !important;
}

[class*=sidebar-dark-] {
  background-color: #253270 !important;
}

[class*=sidebar-dark-] .sidebar a {
  color: white !important;
}

.layout-fixed .main-sidebar {
  width: 250px;
}

body:not(.sidebar-mini-md) .content-wrapper, body:not(.sidebar-mini-md) .main-footer, body:not(.sidebar-mini-md) .main-header {
  margin-left: 250px;
}

.layout-fixed .brand-link {
  width: 250px;
}

.nav-item {
  font-size: 15px;
}

@keyframes example {
  from {background-color: red;}
  to {background-color: transparent;}
}

/* The element to apply the animation to */
.blink {
  background-color: transparent;
  animation-name: example;
  animation-duration: 0.5s;
}

@media(max-width: 991px) {
  .content-wrapper {
    margin-left: 0 !important;
  }
}
